import React, { useContext } from "react";
import PropTypes from "prop-types";
import { darken, lighten, makeStyles } from "../../libraries/material/core";
import BaseLink from "./BaseLink";
import { tocPathShape } from "../../utilities/prop-types";
import { TableOfContentsPositioningContext } from "../context";

/**
 * Component that returns a BaseLink, it accepts all of the same props. This offers a highlighted border to the left, whose color can be of type `primary`
 * or `secondary`, which will look within the associated theme palette's secondary object's `main` color. While it is recommended to leverage the theme for
 * consistent styling, there is an override option if a custom hex code must be used with the prop `htmlColor`.
 * 
 * The color will lighten or darken based on percentVisible. If the element is taking up 95% or higher of the screen, or if 95% of the element is visible,
 * the full color will render. Otherwise, it will use lighten with a coefficient of 1 minus the percent it is taking up of the screen (ofScreen). The 
 * bufferThreshold of .95 is configurable via props to adjust for any design issues.
 * 
 */

const StylizedLink = (props = {}) => {
  const { children, pathId, className, percentVisible: defaultPercentVisible, color, htmlColor, bufferThreshold, ...rest } = props;
  const positions = useContext(TableOfContentsPositioningContext)
  let percentVisible = defaultPercentVisible
  if(pathId && (Object.keys(defaultPercentVisible).length === 0)) {
    percentVisible = positions[pathId]
  }
  
  const classes = useLinkStyles({ percentVisible, color, htmlColor, bufferThreshold  });
  
  let cls = classes.navLink;
  if (className) {
    cls += ` ${className}`;
  }

  return (
    <BaseLink {...rest} className={cls}>
      {children}
    </BaseLink>
  );
};

StylizedLink.defaultProps = {
  percentVisible: {},
  color: "secondary",
  bufferThreshold: .95
};

StylizedLink.propTypes = {
  percentVisible: PropTypes.shape(tocPathShape),
  htmlColor: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  bufferThreshold: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  pathId: PropTypes.string
};

const useLinkStyles = makeStyles((theme) => {
  return {
    navLink: ({ percentVisible, htmlColor, color = 'secondary', bufferThreshold  }) => ({
      ...calculateStyles((htmlColor || theme.palette[color].main), percentVisible, bufferThreshold, theme?.palette?.type),
      boxSizing: "border-box",
      marginBottom: "2px",
      minHeight: "44px",
    }),
  }
});

const calculateStyles = (color = '', percentVisible = {}, bufferThreshold = .95, themeType) => {
  const { ofSelf, ofScreen } = percentVisible;

  const darkMode = themeType === 'dark'

  const fullyVisible = ofScreen > bufferThreshold || ofSelf > bufferThreshold
  const partiallyVisible = ofScreen > 0 || ofSelf > 0

  let borderColor = "transparent";
  if (fullyVisible) {
    borderColor = darkMode ? lighten(color, 1 - ofScreen) : color
  } else if (partiallyVisible) {
    const darkModeMap = {
      .1: .9,
      .2: .8,
      .3: .7,
      .4: .6,
      .5: .5,
      .6: .4,
      .7: .3,
      .8: .2,
      .9: .1,
    }

    const roundingOfSelfForMapping = Math.round(ofSelf * 10) / 10
    const darkModeCoefficient = darkModeMap[roundingOfSelfForMapping]

    borderColor = darkMode ? darken(color, darkModeCoefficient) : lighten(color, 1 - ofSelf);
  }




  let backgroundColor = darkMode ? `rgba(255, 255, 255, 0.05)` :  undefined
  return {
    backgroundColor,
    borderLeft: `solid .25em ${borderColor}`,
  };
};

export default StylizedLink;
