import React from "react";
import image from '../assets/osu-long-logo.png'

const OSU = () => {
  return (
    <a
      aria-label="The Ohio State University home page"
      href="https://www.osu.edu/"
    >
      <img className="fixed-height-imagery" alt="" src={image} />
    </a>
  );
};

export default OSU;
