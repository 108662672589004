import React, { Fragment, useReducer } from "react";
import { Mui, MuiLab } from "../libraries/material";

import PropTypes from "prop-types";
import { Search } from "@material-ui/icons";

const reducer = (state = {}, action = {}) => {
  let newState = {
    filters: {},
    ...state,
  };
  const payload = action?.payload;

  switch (action.type) {
    case "SEARCH":
      newState.searchValue = payload?.value;
      break;
    case "FILTERS":
      if (payload?.field) {
        newState.filters[payload.field] = payload.value;
      }
      break;
    default:
      console.log("Received unknown action type", action.type);
      break;
  }

  if (payload?.onFiltrationUpdate) {
    payload.onFiltrationUpdate(newState);
  }
  return newState;
};

const useStyles = Mui.makeStyles((theme) => {
  return {
    wrapper: {
      display: "flex",
      marginLeft: `-${theme.spacing()}px`,
      marginRight: `-${theme.spacing()}px`,
    },
    item: {
      flexBasis: 0,
      flexGrow: 1,
      maxWidth: "50%",
      marginLeft: `${theme.spacing()}px`,
      marginRight: `${theme.spacing()}px`,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  };
});

function TableFilters(props = {}) {
  const { filters, onFiltrationUpdate } = props;
  const [filterState, setFilterState] = useReducer(reducer, {});
  const classes = useStyles();

  const changeSearch = (event) => {
    setFilterState({
      type: "SEARCH",
      payload: {
        value: event?.target?.value,
        onFiltrationUpdate,
      },
    });
  };

  return (
    <Fragment>
      <Mui.Box display="flex">
        <Mui.TextField
          value={filterState?.searchValue}
          onChange={changeSearch}
          variant="outlined"
          className="flex-grow-1"
          label="Search tasks"
          placeholder="Type in a search query, such as name, description, or Student ID"
          InputProps={{
            startAdornment: (
              <Mui.InputAdornment position="start">
                <Search fontSize="inherit" color="secondary" />
              </Mui.InputAdornment>
            ),
          }}
        />
      </Mui.Box>
      <Mui.Typography
        variant="body2"
        color="secondary"
        className="margin-top-2 margin-bottom-1"
      >
        Filter by:
      </Mui.Typography>
      <Mui.Box className={classes.wrapper}>
        {filters.map(({ id, field, label, values }, index) => {
          return (
            <MuiLab.Autocomplete
              className={classes.item}
              multiple
              id={id}
              value={filterState?.[field]}
              onChange={(event, newValue) => {
                setFilterState({
                  type: "FILTERS",
                  payload: {
                    field,
                    onFiltrationUpdate,
                    value: newValue,
                  },
                });
              }}
              options={values}
              renderInput={(params) => {
                return (
                  <Mui.TextField {...params} variant="outlined" label={label} />
                );
              }}
            />
          );
        })}
      </Mui.Box>
    </Fragment>
  );
}

TableFilters.defaultProps = {
  filters: [],
};

TableFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      values: PropTypes.array,
    })
  ),
};

export default TableFilters;
