export const WINDOW__RUM_FUNCTION = "otdiRUMEvent"
export function sendRUMEvent(eventType, payload) {
    if(window?.[WINDOW__RUM_FUNCTION]) {
        window?.[WINDOW__RUM_FUNCTION](eventType, payload)
    }
}

export function sendRumPageView(location = {}) {
    let path = location?.pathname;
    if (location.search) path += location.search;

    sendRUMEvent('recordPageView', path);
}