import { Mui } from "../../libraries/material";
const backgroundColor = "#333333";

const FooterLink = Mui.withStyles((theme) => {
  const contrast = theme.palette.getContrastText(backgroundColor);

  return {
    root: {
      color: contrast,
      borderBottom: `1px solid ${contrast}`,
      "&:hover": {
        color: backgroundColor,
        backgroundColor: contrast,
        borderBottom: `1px solid ${backgroundColor}`,
        textDecoration: "none",
      },
    },
  };
})(Mui.Link);

const FooterSocialLink = Mui.withStyles((theme) => {
    const backgroundColor = "#333333";
    const contrast = theme.palette.getContrastText(backgroundColor);
  
    return {
      root: {
        color: contrast,
        "&:hover": {
          color: backgroundColor,
          textDecoration: "none",
          backgroundColor: contrast
        },
      },
    };
  })(Mui.Link);

export {
    FooterLink,
    FooterSocialLink
};
