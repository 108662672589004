import React, { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { sendRumPageView, WINDOW__RUM_FUNCTION } from "../utilities/rum";
import { Helmet } from "react-helmet";

const withRUMListener = (Component) => {
  Component.defaultProps = {
    version: process.env.REACT_APP_VERSION,
    region: "us-east-2",
    cwr: "https://client.rum.us-east-1.amazonaws.com/1.12.0/cwr.js",
  };
  return function _withRumListener(props) {
    const { appMonitorId, version, region, config, cwr, ...rest } = props;

    const history = useHistory();
    useEffect(() => {
      return history.listen(sendRumPageView);
    }, [history]);

    const telemetries = config?.telemetries || [
      "performance",
      "errors",
      "http",
    ];
    const script = `(function(n,i,v,r,s,c,x,z){x=window.AwsRumClient={q:[],n:n,i:i,v:v,r:r,c:c};window[n]=function(c,p){x.q.push({c:c,p:p});};z=document.createElement('script');z.async=true;z.src=s;document.head.insertBefore(z,document.head.getElementsByTagName('script')[0]);})(
      '${WINDOW__RUM_FUNCTION}',
      '${appMonitorId}',
      '${version || "1.0.0"}',
      '${region}',
      '${cwr}',
      {
        sessionSampleRate: '${config?.sessionSampleRate || ""}',
        guestRoleArn: '${config?.guestRoleArn || ""}',
        identityPoolId: '${config?.identityPoolId || ""}',
        endpoint: '${config?.endpoint || ""}',
        telemetries: ${
          Array.isArray(telemetries) ? `${JSON.stringify(telemetries)}` : `[]`
        },
        allowCookies: ${config?.allowCookies ? "true" : "false"},
        enableXRay: ${config?.enableXRay ? "true" : "false"}
      }
    );`;

    return (
      <Fragment>
        <Helmet>{<script>{script}</script>}</Helmet>
        <Component {...rest} />
      </Fragment>
    );
  };
};

export default withRUMListener;
