import React from "react";
import PropTypes from "prop-types";
import Logo from "../Logo";
import Contact from "../Contact/";
import SocialList from "../Social";
import Accessibility from "../Accessibility";
import Util from "../Util";
import "../sass/bux-styles.scss";
import "./footer.scss";

/**
 * Basic installation:
 * 
 * `npm install @osu/footer`
 *
 * 
 * ```
 * import Footer from "@osu/footer";
 * 
 * function App(props = {}) {
 *    return ...
 *     <Footer />
 *    ...
 * }```
 */

const Footer = React.forwardRef((props = {}, ref) => {
  const {
    className,
    children,
    darkMode,
    address,
    phone,
    email,
    socialLinks,
    footerLinks,
    white,
    palette,
    ...rest
  } = props;
  let themeType = darkMode ? "dark" : "light";

  const theme = white ? 'white': themeType
  let footerClass = `bux-footer bux-footer--${theme}`;
  if (className) footerClass += ` ${className}`;

  return (
    <footer ref={ref} className={footerClass} {...rest}>
      <div className="bux-container bux-grid">
        <div className="bux-footer__col bux-footer__col--left bux-grid__cell bux-grid__cell--12 bux-grid__cell--6@md">
          <Logo darkMode={darkMode} white={white} />
          <Contact address={address} phone={phone} email={email} />
        </div>
        <div className="bux-footer__col bux-footer__col--right bux-grid__cell bux-grid__cell--12 bux-grid__cell--6@md">
          {!!socialLinks.length && <SocialList links={socialLinks} theme={theme} />}
          <div className={`bux-footer__fine-print`}>
            <Accessibility />
            {!!footerLinks.length && (
              <div className="bux-footer__links">
                {footerLinks.map(({ href, label }, index) => {
                  return (
                    <p key={`footer-link-${index}`} className="bux_footer__link">
                      <Util.Link href={href}>{label}</Util.Link>
                    </p>
                  );
                })}
              </div>
            )}
            <div className="bux-footer__copyright">
              © {new Date().getFullYear()} The Ohio State University
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});

Footer.defaultProps = {
  className: "",
  address: {
    orgName: "Office of Technology and Digital Innovation",
    addressLine1: "100 Mount Hall",
    addressLine2: "1050 Carmack Road",
    city: "Columbus",
    state: "OH",
    zip: "43210",
  },
  email: {
    label: "ServiceDesk@osu.edu",
    href: "mailto:ServiceDesk@osu.edu",
  },
  phone: {
    label: "614-688-4357",
    href: "tel:614-688-4357",
  },
  darkMode: false,
  white: false,
  socialLinks: [
    "https://www.facebook.com/OhioStateOnline/",
    "https://twitter.com/ohiostateit",
    "https://www.youtube.com/channel/UC6ga6ob19_0SZMYpHryeFEA",
    "https://www.linkedin.com/company/office-of-distance-education-and-elearning/",
    "https://www.instagram.com/techohiostate/",
  ],
  footerLinks: [
    {
      href: "https://www.osu.edu/privacy/",
      label: "Privacy Statement",
    },
    {
      href: "https://www.osu.edu/assets/non-discrimination-notice-2022-05-18.pdf",
      label: "Non-discrimination Notice",
    }
  ]
};


Footer.propTypes = {
  darkMode: PropTypes.bool,
  white: PropTypes.bool,
  className: PropTypes.string,
  address: PropTypes.shape({
    orgName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  email: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }),
  phone: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }),
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    })
  ),
  socialLinks: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        type: PropTypes.string,
        href: PropTypes.string.isRequired,
        icon: PropTypes.string,
        hiddenAccessibleText: PropTypes.string,
      }),
    ])
  ),
  palette: PropTypes.shape({
    light: PropTypes.shape({
      default: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      }),
      hover: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      }),
      focus: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      }),
      visited: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      })
    }),
    dark: PropTypes.shape({
      default: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      }),
      hover: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      }),
      focus: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      }),
      visited: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      })
    }),
    white: PropTypes.shape({
      default: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      }),
      hover: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      }),
      focus: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      }),
      visited: PropTypes.shape({
        color: PropTypes.string,
        backgroundColor: PropTypes.string
      })
    })
  })
};

export default Footer;
