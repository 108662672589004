import { useCallback, useContext, useEffect, useRef } from "react";
import { TOC } from "../../TableOfContents/index";
import "../FooterTOC.scss";

const useFooterWithTOC = ({ ref: footerRef }) => {
  const scrollTimeout = useRef();
  const adjustFooterAt = useRef();
  const tocContext = useContext(TOC.Context)
  const toc = document.getElementById('table-of-contents')
  const tocList = toc?.querySelector('UL')
  const boundingClientRect =  tocList && tocList.getBoundingClientRect()
  const tocListItems = tocList?.querySelectorAll('LI')
  const adjustToc = tocContext?.paths?.length > 0 
    && (tocContext?.paths?.length === tocListItems?.length)
    const footerHeight = footerRef?.current?.clientHeight

  useEffect(() => {
    const root = document.documentElement
    root.style.setProperty('--footer-height', footerHeight + 'px')
  }, [footerHeight])

  useEffect(() => {
    const hasStatic = toc?.classList?.contains('toc-static')
  
    if(!hasStatic && adjustToc && boundingClientRect.height) {
        const newHeight = (boundingClientRect.height + boundingClientRect.top)
        if(adjustFooterAt.current !== newHeight) {
          adjustFooterAt.current = newHeight
        }
    }
  }, [adjustToc, boundingClientRect, toc?.classList])


  const handleScroll = useCallback(() => {
    const element = footerRef?.current
    
    if (!element || !adjustFooterAt.current) { 
      return null;
    }

    clearTimeout(scrollTimeout.current);

    scrollTimeout.current = setTimeout(() => {
      const footerDimensions = element.getBoundingClientRect()
      const hasStatic = toc?.classList?.contains('toc-static')
      const hasFixed = toc?.classList?.contains('toc-fixed')

      const overlap = (adjustFooterAt.current) > footerDimensions?.top

      if(toc && !overlap && !hasFixed) {
        hasStatic && toc.classList.remove('toc-static')
        toc.classList.add('toc-fixed')
      } else if(toc && overlap && !hasStatic) {
        hasFixed && toc.classList.remove('toc-fixed')
        toc.classList.add('toc-static')
      }      
      
    }, 50);
  }, [toc]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      capture: false,
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
}

export default useFooterWithTOC