import React from "react";
import PropTypes from "prop-types";
import { Fragment } from "react";
import Address from "./Address";
import EmailPhone from "./EmailPhone";

const Contact = (props = {}) => {
  const { email, phone, address } = props;

  return (
    <Fragment>
      <Address {...address} />
      <EmailPhone phone={phone} email={email} />
    </Fragment>
  );
};

Contact.defaultProps = {
  email: {
    label: "OTDI@osu.edu",
    href: "mailto:OTDI@osu.edu",
  },
  phone: {
    label: "614-688-4357",
    href: "tel:614-688-4357",
  },
  address: {}
};

Contact.propTypes = {
  email: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }),
  phone: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }),
  address: PropTypes.shape({
    orgName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
};

export default Contact;
