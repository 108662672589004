import React from "react";
import PropTypes from "prop-types";
import { Popover as MuiPopover, List } from "../../libraries/material/core";
import { Skeleton } from "../../libraries/material/lab";
import BaseLink from "./BaseLink";
import { tocPathShape } from "../../utilities/prop-types";

const Popover = (props = {}) => {
  const { paths, ListProps, liProps, BaseLinkProps, hideOnEmpty, ...rest } = props;
  if(paths.length < 1 && hideOnEmpty) {
    return null
  }

  return (
    <MuiPopover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...rest}
    >
      <List {...ListProps}>
        {paths.map(({ id, label, loading }, index) => {
          const href = `#${id}`;
          if (loading) {
            return (
              <li {...liProps} key={`popover-li-loading-${index}${href}`}>
                <Skeleton className="margin-x-1" width="15rem" />
              </li>
            );
          }
          return (
            <li {...liProps} key={`popover-li-${index}${href}`}>
              <BaseLink
                divider={paths.length - 1 !== index}
                href={href}
                {...BaseLinkProps}
              >
                {label}
              </BaseLink>
            </li>
          );
        })}
      </List>
    </MuiPopover>
  );
};

Popover.defaultProps = {
  paths: [],
  BaseLinkProps: {},
  ListProps: {},
  liProps: {},
  hideOnEmpty: true
};

Popover.propTypes = {
  ListProps: PropTypes.object, 
  liProps: PropTypes.object,
  paths: PropTypes.arrayOf(
    PropTypes.shape(tocPathShape)
  ),
  hideOnEmpty: PropTypes.bool,
  BaseLinkProps: PropTypes.shape({
    onClick: PropTypes.func,
    color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  }),
};

export default Popover