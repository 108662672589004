import { typeColor, rootDarkColor, standardColor, darkModeColor } from "./palette";

const { standard, darkMode } = typeColor



const standardPalette = {
    colors: standardColor,
    type: 'light',
    background: {
        default: "#fafafa"
    },
    text: {
        default: standard.default
    },
    textColor: standard.default,
    primary: {
        main: standard.action,
    },
    secondary: {
        main: standard.secondary,
    },
    textPrimary: {
        main: standard.action
    }
}

const darkModePalette = {
    type: 'dark',
    colors: darkModeColor,
    background: {
        default: rootDarkColor
    },    
    textColor: darkMode.default,
    primary: {
        main: darkMode.action,
    },
    secondary: {
        main: darkMode.secondary,
    }
}

export { standardPalette, darkModePalette }