import React, { useContext, useRef } from "react";
import { Fragment } from "react";
import { Typography } from "../../libraries/material/core"
import { useScrollToTop } from "../../hooks"
import { Helmet } from "../../libraries/Helmet"
import { PageHeadingContext } from "../context";

const PageHeading = (props = {}) => {
  const headerRef = useRef();
  const { heading = '', pageTitle: title = "", applicationName = "", options = {} } = useContext(PageHeadingContext);

  useScrollToTop({ ref: headerRef });

  let { ...typographyProps } = Object.assign({}, options, props)
  
  let pageTitle = title
  if(!pageTitle) {
    pageTitle = heading 
  }
  if(applicationName && !pageTitle.includes(applicationName)) {
    pageTitle += ` - ${applicationName}`
  }
  if(typeof pageTitle !== "string") pageTitle = (typeof applicationName === "string" ? applicationName : "")

  if (!heading) return null;
  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Typography
        ref={headerRef}
        {...typographyProps}
      >
        {heading}
      </Typography>
    </Fragment>
  );
};

PageHeading.defaultProps = {
  variant: "h1",
  component: "h1",
  className: "",
  tabIndex: '-1'
};

export default PageHeading;
