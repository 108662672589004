import { Mui, MuiLab } from "../libraries/material";
import React, { Fragment } from "react";
import { isObject } from "../utilities/functions";

function Table(params) {
  const {
    status,
    columns,
    rows,
    pagination,
    rowActions,
    supportEmail,
  } = params;
  const loading = !rows.length && status === "loading";
  let supplementaryText = "";
  if (!rows?.length)
    supplementaryText = (
      <Fragment>
        No rows found. If you believe this to be in error, please contact{" "}
        <Mui.Link href={`mailto:${supportEmail}`}>{supportEmail}</Mui.Link>.
      </Fragment>
    );
  if (loading) supplementaryText = "Loading forms...";
  const rowActionsWidth = rowActions.length * 10;

  let alteredWith = (width) => {
    let newWidth = width;
    if (width.endsWith("%")) {
      newWidth.replace("%", "");
      newWidth = parseFloat(newWidth);
      const splitWidth = rowActionsWidth / columns.length;
      newWidth = newWidth - splitWidth;
      newWidth = newWidth + "%";
    }
    return newWidth;
  };

  return (
    <Fragment>
      <Mui.Table aria-label="history">
        <Mui.TableHead>
          <Mui.TableRow>
            {columns.map((col, index) => {
              return (
                <Mui.TableCell
                  key={`${index}-${col.dataField}-tablecol`}
                  width={alteredWith(col.width)}
                  align="left"
                >
                  {col.label}
                </Mui.TableCell>
              );
            })}
            {!!rowActions.length && (
              <Mui.TableCell width={rowActionsWidth + "%"} align="left">
                Actions
              </Mui.TableCell>
            )}
          </Mui.TableRow>
        </Mui.TableHead>
        <Mui.TableBody>
          {rows.map((row, rowIdx) => (
            <Mui.TableRow key={`${rowIdx}-tablerow`} >
              {columns.map((col, index) => {
                let props =
                  index === 0
                    ? {
                        component: "th",
                        scope: "row",
                      }
                    : {};
                return (
                  <Cell
                    key={`${index}-${col.dataField}-${
                      row[col.dataField]
                    }-tablerow`}
                    {...props}
                    {...col}
                    row={row}
                    width={alteredWith(col.width)}
                  />
                );
              })}
              {rowActions?.length && (
                <Mui.TableCell
                  key={`${rowIdx}-tablerowactions`}
                  width={rowActionsWidth + "%"}
                  align="left"
                >
                  {rowActions.map((action, idx) => {
                    const { label, onClick, ...rest } = action;
                    return (
                      <Mui.Button
                        {...rest}
                        key={`${rowIdx}-${idx}-tablerow-button`}
                        onClick={(event) => {
                          onClick(event, row);
                        }}
                      >
                        {label}
                      </Mui.Button>
                    );
                  })}
                </Mui.TableCell>
              )}
            </Mui.TableRow>
          ))}
        </Mui.TableBody>
      </Mui.Table>
      {loading && <Mui.LinearProgress className="width-100" />}
      {supplementaryText && (
        <Mui.Typography className="text-align-center margin-top-3 margin-bottom-2">
          {supplementaryText}
        </Mui.Typography>
      )}
      {pagination && (
        <MuiLab.Pagination
          className="margin-top-1"
          classes={{
            ul: "justify-content-flex-end",
          }}
          count={10}
          color="primary"
        />
      )}
    </Fragment>
  );
}

const Cell = (props) => {
  const { width, dataField, row, ...rest } = props;
  let value = row[dataField];
  if (value?.Component) {
    value = <value.Component />;
  } else if (isObject(value)) {
    const { Icon, label } = value;
    value = (
      <Fragment>
        {Icon && <Icon className="margin-right-1" fontSize="inherit" />}
        {label}
      </Fragment>
    );
  }
  return (
    <Mui.TableCell width={width} align="left" {...rest}>
      {value}
    </Mui.TableCell>
  );
};

Table.defaultProps = {
  rowActions: [],
  supportEmail: "oasis-support@lists.osu.edu",
};

export default Table;
