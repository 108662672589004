import React from "react";

function More(props = {}) {
  const { fill, title, ...rest } = props;
  return (
    <svg {...rest} viewBox="0 0 5 20" version="1.1">
      <title>{title}</title>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="more"
          transform="translate(0.250990, 0.749010)"
          fill={fill}
          fill-rule="nonzero"
        >
          <g
            id="menu"
            transform="translate(1.958010, 9.209000) rotate(90.000000) translate(-1.958010, -9.209000) translate(-7.250990, 7.250990)"
          >
            <rect
              id="Rectangle"
              opacity="0"
              x="0"
              y="0"
              width="18.418"
              height="3.91602"
            ></rect>
            <path
              d="M1.95312,3.91602 C3.05664,3.91602 3.93555,3.04688 3.93555,1.96289 C3.93555,0.878906 3.05664,0.00976562 1.95312,0.00976562 C0.878906,0.00976562 0,0.878906 0,1.96289 C0,3.04688 0.878906,3.91602 1.95312,3.91602 Z"
              id="more-path"
            ></path>
            <path
              d="M9.20898,3.91602 C10.293,3.91602 11.1621,3.04688 11.1621,1.96289 C11.1621,0.878906 10.293,0.00976562 9.20898,0.00976562 C8.13477,0.00976562 7.26562,0.878906 7.26562,1.96289 C7.26562,3.04688 8.13477,3.91602 9.20898,3.91602 Z"
              id="more-path"
            ></path>
            <path
              d="M16.4648,3.91602 C17.5488,3.91602 18.418,3.04688 18.418,1.96289 C18.418,0.878906 17.5488,0.00976562 16.4648,0.00976562 C15.3613,0.00976562 14.4922,0.878906 14.4922,1.96289 C14.4922,3.04688 15.3613,3.91602 16.4648,3.91602 Z"
              id="more-path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

More.defaultProps = {
  height: "20px",
  fill: "#000000",
  width: "5px",
  title: "more"
};

export default More;
