import React from "react";
import PropTypes from "prop-types";
import { ListItem } from "../../libraries/material/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { scrollToHash } from "../../utilities/domMutations";

/**
 * Component with minimal styles that pushes a hash to history, adds a disableScroll boolean set to true to routing state for use in custom hooks,
 * and scrolls to the section whose id matches the hash.
 *
 * Optionally, an onClick event can be passed to this link. This onClick will execute immediately, then a timeout will be created with a
 * configurable time of 50 milliseconds, at which point the scroll to hash and history update will occur.
 */

const BaseLink = (props = {}) => {
  const { href, children, onClick, timeout, role, ...rest } = props;
  const history = useHistory();

  const navigationHandler = (event) => {
    event.preventDefault();

    history.push({
      hash: href,
      state: {
        disableScroll: true,
      },
    });

    scrollToHash(document, href);
  };

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
      setTimeout(() => {
        navigationHandler(event);
      }, [timeout]);
    } else {
      navigationHandler(event);
    }
  };

  return (
    <ListItem
      button
      role={role}
      component={RouterLink}
      onClick={handleClick}
      to={href}
      {...rest}
    >
      {children}
    </ListItem>
  );
};

BaseLink.defaultProps = {
  href: "",
  role: "link",
  timeout: 50
};

BaseLink.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  href: PropTypes.string.isRequired,
  role: PropTypes.string,
  timeout: PropTypes.number
};

export default BaseLink;
