import React from "react";
import { Button, Modal, makeStyles } from "../../libraries/material/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "80vh",
        height: "80vh",
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    iframe: {
        height: "80vh",
        width: "100vh"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));
function PDFModal(props = {}) {
    const classes = useStyles();
    const Component = Modal;
    return (
        <Component open={props?.open} className={classes.modal}>
            <div>
                <Button variant="contained" color="primary" onClick={props?.closeModal}   >
                    X
                </Button>
                <br />
                <iframe className={classes.iframe} src={props?.file} />
            </div>
        </Component>
    );
}

export default PDFModal;