import React from "react";
import PropTypes from "prop-types";
import Util from "../../../Util/";

const EmailPhone = (props = {}) => {
  const { email, phone } = props;

  return (
    <div className="bux-footer__contact bux-footer__contact--email-phone">
      <hr />
      {email?.href && email?.label && <p className="bux_footer__email">
        <Util.Link href={email.href}>
          {email.label}
        </Util.Link>
      </p>}
     {phone?.href && phone?.label && <p className="bux_footer__phone">
        <Util.Link href={phone.href}>
          {phone.label}
        </Util.Link>
      </p>}
    </div>
  );
};

EmailPhone.defaultProps = {
  email: {
    label: "OTDI@osu.edu",
    href: "mailto:OTDI@osu.edu",
  },
  phone: {
    label: "614-688-4357",
    href: "tel:614-688-4357",
  },
};

EmailPhone.propTypes = {
  email: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }),
  phone: PropTypes.shape({
    label: PropTypes.string,
    href: PropTypes.string,
  }),
};

export default EmailPhone;
