import React, { useState } from "react";
import Close from "../Components/Icons/Close";
import More from "../Components/Icons/More";
import NavLinksList from "../Components/NavLinks/List";
import Wordmark from "../Components/Wordmark";
import "../sass/bux-styles.scss";
import "./index.scss"

function Navbar(props = {}) {
  const { darkMode, id, mainHref } = props;
  const [menuOpen, triggerMenuOpen] = useState(false);
  let btnTriggerClass = menuOpen ? "activate-nav clicked" : "activate-nav";

  const toggleMenu = () => triggerMenuOpen((currentState) => !currentState);
  const fill = darkMode ? "#FFFFFF" : "#000000";
  return (
    <nav
      id={id}
      className={
        "bux-resets bux-osu-nav" +
        (darkMode ? " dark-mode" : "") +
        (menuOpen ? " menu-open" : "")
      }
    >
      <h2 className="visually-hidden">Ohio State navigation bar</h2>
      {mainHref && <a href={mainHref} id="skip" className="osu-semantic">Skip to main content</a>}
      <div className="bux-container">
        {!menuOpen && <Wordmark darkMode={darkMode} />}
        <div id="osu-navlinks-block">
          <button
            onClick={() => toggleMenu()}
            id="osu-nav-trigger"
            className={btnTriggerClass}
          >
            {menuOpen ? <Close title="close links menu" fill={fill} /> : <More title="open links menu" fill={fill} />}
          </button>
          <NavLinksList />
        </div>
      </div>
    </nav>
  );
}

Navbar.defaultProps = {
  id: "osu-navbar",
  darkMode: false,
  mainHref: "#page-content"
};

export default Navbar;
