import React from "react";
import PropTypes from "prop-types";
import { Link } from "../../libraries/material/core";
import { LaunchIcon } from "../../libraries/material/icons";

const _Link = ({ href, children }) => {
  return (
    <Link variant="subtitle2" target="_blank" rel="noopener" href={href}>
      {children}
      <LaunchIcon className="ml-1" fontSize="inherit" />
    </Link>
  );
};

_Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node
};

export default _Link;
