import { useCallback, useState } from 'react'

const usePageHeading = () => {
    const [heading, setHeading] = useState('')
    const [options, setOptions] = useState({})
    const [applicationName, setApplicationName] = useState('')
    const [pageTitle, setPageTitle] = useState('')
    
    const setCurrentHeading = useCallback((newHeading = '') => {
        setHeading(current => {
            if(current !== newHeading) {
                return newHeading
            }
        })
    }, [])

    const setCurrentOptions = useCallback((newOptions = {}) => {
        setOptions(current => {
            if(current !== newOptions) {
                return newOptions
            }
        })
    }, [])

    const setCurrentApplicationName = useCallback((newAppName = "") => {
        setApplicationName(current => {
            if(current !== newAppName) {
                return newAppName
            }
        })
    }, [])

    const setCurrentPageTitle = useCallback((newPageTitle = "") => {
        setPageTitle(current => {
            if(current !== newPageTitle) {
                return newPageTitle
            }
        })
    }, [])

    return {
        heading,
        applicationName,
        options,
        pageTitle,
        setHeading: setCurrentHeading,
        setOptions: setCurrentOptions,
        setApplicationName: setCurrentApplicationName,
        setPageTitle: setCurrentPageTitle
    }
}

export default usePageHeading