import { useEffect, useMemo, useState } from "react";
import { MuiStyles } from "../libraries/material/"
import { darkModePalette, standardPalette } from "../Theming/colors";

const useDarkMode = (override = undefined, options = {}) => {
  const { textColor: optionalTextColor = '', backgroundColor: optionalBackground = '' } = options
  const mediaQueryList = useMemo(
    () => window.matchMedia("(prefers-color-scheme: dark)"),
    []
  );
  const [matches, setMatches] = useState(mediaQueryList.matches);
  const theme = MuiStyles.useTheme()
  const darkMode = override !== undefined ? override : matches

  useEffect(() => {
    const listener = (event) => {
      setMatches(event.matches);
    };

    mediaQueryList.addEventListener("change", listener);

    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, [mediaQueryList]);

  useEffect(() => {
    const rootElement = document.getElementById('root')
    const osuPalette = darkMode ? darkModePalette : standardPalette

    const setProperty = (property, value) => rootElement.style.setProperty(property, value)

    let backgroundColor = '#fafafa'
    let textColor = '#000000'

    const paletteInSyncWithMode = (darkMode && theme?.palette?.type === 'dark') || (!darkMode && theme?.palette?.type === 'light')

    if(paletteInSyncWithMode) {
      backgroundColor = theme.palette.background.default
      textColor = theme.palette.textColor
    } else {
      backgroundColor = osuPalette.background.default
      textColor = osuPalette.textColor
    }

    if (optionalTextColor) {
      textColor = optionalTextColor
    }
    if (optionalBackground) {
      backgroundColor = optionalBackground
    }

    setProperty('--root-background-color', backgroundColor)
    setProperty('--root-text-color', textColor)
    
    if(darkMode) {
      rootElement.classList.add('dark-mode-enabled')
    } else {
      rootElement.classList.remove('dark-mode-enabled')
    }
  }, [darkMode])

  return {
    darkMode
  };
};

export default useDarkMode;
