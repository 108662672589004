import React from 'react'
import { makeStyles, Typography } from "../../libraries/material/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => {
  return {
    anchorLink: ({ navHeight }) => {
      return {
        paddingBottom: '.5em',
        "&::before": {
          display: "block",
          content: '" "',
          marginTop: `calc(-1 * (${navHeight}px + .5em))`,
          height: `calc(${navHeight}px + .5em)`,
          visibility: `hidden`,
        },
      };
    },
  };
});

const AnchorHeading = (props = {}) => {
  const { variant, tabIndex, navHeight, className, children, ...rest } = props;
  const classes = useStyles({ navHeight });

  return (
    <Typography 
      {...rest} 
      variant={variant} 
      tabIndex={tabIndex} 
      className={`${classes.anchorLink} ${className}`.trim()}
    >
      {children}
    </Typography>
  );
};

AnchorHeading.defaultProps = {
  className: "",
  variant: "h2",
  component: "h2",
  tabIndex: "-1",
};

AnchorHeading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navHeight: PropTypes.number,
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
    "overline",
    "srOnly",
    "inherit",
  ]),
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AnchorHeading