import { format, isValid } from "date-fns";

export function omitReduxProps(props = {}) {
  const clonedObject = Object.assign({}, props);
  delete clonedObject.history;
  delete clonedObject.location;
  delete clonedObject.match;
  delete clonedObject.staticContext;
  delete clonedObject.dispatch;

  return clonedObject;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatValue(value) {
  let val = value ?? "";
  if (isValid(new Date(val))) {
    val = format(new Date(val), "MMMM d, yyyy h:mm bbb");
  }
  if (val.toUpperCase() === val) {
    val = capitalizeFirstLetter(val.toLowerCase());
  }

  return val;
}

export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

export function deepCopy(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        } else {
          target[key] = Object.assign({}, target[key]);
        }
        deepCopy(target[key], source[key]);
      } else {
        let keyValue = source[key];
        if (Array.isArray(source[key])) {
          keyValue = JSON.parse(JSON.stringify(source[key]));
        }
        Object.assign(target, { [key]: keyValue });
      }
    }
  }

  return deepCopy(target, ...sources);
}

export function mapValues(obj = {}, callback) {
  let newObj = {}
  let result = Object.keys(obj)
    result.forEach(field => {
      const originalField = obj[field]
      newObj[field] = callback(originalField)
    }) 
  return newObj;
}

export function generateUUID (prefix) {
  let num = Math.floor(Math.random() * Date.now()).toString(16)
  if(prefix) {
    return `${prefix}-${num}`
  }
  return num
}