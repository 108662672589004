import React from "react";
import PropTypes from "prop-types";
import "../Footer.scss";
import { Mui } from "../../libraries/material";
import OSU from "./OSU";
import Twitter from "@material-ui/icons/Twitter";
import { Facebook, Image, YouTube } from "@material-ui/icons";
import Instagram from "@material-ui/icons/Instagram";
import { FooterLink, FooterSocialLink } from './Link'

const useStyles = Mui.makeStyles((theme) => {
  return {
    footer: {
      position: "relative",
      zIndex: theme?.zIndex?.drawer,
      color: 'white'
    },
    orgHeader: {
      color: 'white'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      }
    },
    secondSection: {
      [theme.breakpoints.up('md')]: {
        textAlign: 'right',
        alignSelf: 'right',
      }
    }
  };
});

const icons = {
    TWITTER: <Twitter />,
    YOUTUBE: <YouTube />,
    INSTAGRAM: <Instagram />,
    FACEBOOK: <Facebook />
}

const Footer = React.forwardRef((props = {}, ref) => {
  const classes = useStyles();
  const {
    copyright,
    organization,
    address,
    linesOfContact,
    precontact,
    postcontact,
    maintainedBy,
    socialMedia
  } = props;

  return (
    <Mui.Typography ref={ref} component="footer" variant="body2" className={classes.footer}>
      <Mui.Container className={classes.container}>
        <Mui.Box>
          <OSU />
          {precontact}
          <section className="margin-x-3 margin-y-2">
            <Mui.Typography className={classes.orgHeader} component="span" variant="subtitle2">{organization}</Mui.Typography>
            <Mui.Box display="flex">
              {address}
              {linesOfContact?.length > 0 && (
                <Mui.Box display="flex" flexDirection="column">
                  {linesOfContact.map((line, index) => {
                    const { label, link } = line;
                    if (label && link) {
                      return (
                        <span key={`${index}-loc-${encodeURIComponent(link.label)}`}>
                          {label}:&nbsp;
                          <FooterLink href={link.href}>{link.label}</FooterLink>
                        </span>
                      );
                    }
                    return line;
                  })}
                </Mui.Box>
              )}
            </Mui.Box>
          </section>
          {postcontact}
        </Mui.Box>
        <Mui.Box display="flex" flexDirection="column" className={classes.secondSection}>
        {
            socialMedia?.length > 0 
              && <Mui.Box className="margin-bottom-1">
                  {socialMedia.map((media, index) =>  <Mui.IconButton 
                    key={index + media.type + '#icon-button'}
                    role={undefined} 
                    component={FooterSocialLink} 
                    href={media.href} 
                    aria-label={media.label}
                    className={socialMedia.length - 1 !== index ? 'margin-right-1' : ''}>
                      {media.icon || icons[media?.type] || <Image />}
                  </Mui.IconButton>)}
                </Mui.Box>
          }
          {maintainedBy?.href && <span>Page maintained by the{" "}
            <FooterLink href={maintainedBy.href}>
              {maintainedBy.label}
            </FooterLink>
          </span>}
          <span>
            Request accessibility help at{" "}
            <FooterLink href="tel:+16142925000">614-292-5000</FooterLink>
          </span>
          <span className="margin-top-2">
            {copyright ||
              `© ${new Date().getFullYear()} The Ohio State University`}
          </span>
        </Mui.Box>
      </Mui.Container>
    </Mui.Typography>
  );
})

Footer.displayName = "Footer"

Footer.defaultProps = {
  copyright: "",
  organization: "Office of the Chief Information Officer",
  maintainedBy: {
    href: "mailto:oasis-support@lists.osu.edu",
    label: "Web and Mobile Team",
  },
  socialMedia: [
    {
        type: 'TWITTER',
        label: 'Twitter',
        href: "https://twitter.com/TechOhioState"
    },
    {
      type: 'YOUTUBE',
        label: 'YouTube',
        href: "https://www.youtube.com/user/TechOhioState"
    },
    {
        type: 'INSTAGRAM',
        label: 'Instagram',
        href: "https://www.instagram.com/techohiostate/"
    },
    {
      type: 'FACEBOOK',
      label: 'Facebook',
      href: 'https://www.facebook.com/OhioStateOnline/'
    }
  ],
  linesOfContact: [
    {
      label: "Contact",
      link: {
        href: "https://ocio.osu.edu/help",
        label: "IT Service Desk",
      },
    },
    {
      label: "Phone",
      link: {
        href: "tel:+1-614-688-4357",
        label: "614-688-4357",
      },
    },
  ],
};

Footer.propTypes = {
  copyright: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  maintainedBy: PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  address: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  linesOfContact: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.shape({
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  })),
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      icon: PropTypes.element,
    }),
  ),
  precontact: PropTypes.element,
  postcontact: PropTypes.element,
};

export default Footer;
