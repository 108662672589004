import usePrevious from "./usePrevious";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const createTimeout = (focusableElement) => {
  const clearTimer = setTimeout(() => {
    if (focusableElement) {
      focusableElement.focus();
    }
  }, 100);

  return () => {
    clearTimeout(clearTimer);
  };
}

const useScrollToTop = ({ ref }) => {
  const { hash = '', pathname = '' } = useLocation()
  const priorPath = usePrevious(pathname)
  
  useEffect(() => {
    if(!hash && (priorPath !== pathname)) {
      window.scrollTo(0, 0);
      createTimeout(ref.current)
    }
  }, [hash, pathname, priorPath, ref])
};

export default useScrollToTop;
