import React from "react";
import PropTypes from 'prop-types';
import { Tooltip, IconButton } from "../../libraries/material/core";
import { TocIcon } from "../../libraries/material/icons";

const PopoverButton = (props = {}) => {
  const { TooltipProps, IconButtonProps, onClick } = props;
  let tooltipProps = Object.assign(
    {
      title: "On this page",
      "aria-label": "on this page",
    },
    TooltipProps
  );
  let iconBtnProps = Object.assign(
    {
      onClick,
      size: "small",
      color: "secondary",
      className: "",
    },
    IconButtonProps
  );

  return (
    <Tooltip {...tooltipProps}>
      <IconButton {...iconBtnProps}>
        <TocIcon />
      </IconButton>
    </Tooltip>
  );
};

PopoverButton.defaultProps = {
  TooltipProps: {},
  IconButtonProps: {},
};

PopoverButton.propTypes = {
  TooltipProps: PropTypes.object, 
  IconButtonProps: PropTypes.object, 
  onClick: PropTypes.func
}

export default PopoverButton;
