import React from "react";
import PropTypes from 'prop-types';
import {
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
} from "@material-ui/core";
import baseTheme from "./index";
import { Helmet } from "../libraries/Helmet";
import '../styles/index.scss'

function OSUThemeProvider (props = {}) {
    const { children, theme, darkMode, embedFonts } = props
    const originalTheme = baseTheme(darkMode)
    const constructedTheme = Object.assign(originalTheme, theme)

    return (
      <MuiThemeProvider theme={constructedTheme}>
        <CssBaseline />
        <Helmet>
          {embedFonts && <link rel="stylesheet" href="https://www.osu.edu/assets/fonts/webfonts.css" />}
        </Helmet>
        {children}
      </MuiThemeProvider>
    );
}

OSUThemeProvider.defaultProps = {
    theme: {},
    darkMode: false,
    embedFonts: true,
  }
  
  OSUThemeProvider.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.shape({
        typography: PropTypes.object,
        overrides: PropTypes.object,
        palette: PropTypes.object
    }),
    darkMode: PropTypes.bool,
    embedFonts: PropTypes.bool
}

export default OSUThemeProvider