import React from "react";

function Close(props = {}) {
    const { fill, title, ...rest } = props
  return (
    <svg {...rest} viewBox="0 0 16 16" version="1.1">
      <title>{title}</title>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="close" fill={fill} fill-rule="nonzero">
          <rect
            id="Rectangle"
            opacity="0"
            x="0"
            y="0"
            width="15.4859"
            height="15.4956"
          ></rect>
          <path
            d="M0.252699,15.2429 C0.594496,15.575 1.1609,15.575 1.49293,15.2429 L7.74293,8.99293 L13.9929,15.2429 C14.325,15.575 14.9011,15.5847 15.2332,15.2429 C15.5652,14.9011 15.5652,14.3445 15.2332,14.0125 L8.98317,7.7527 L15.2332,1.5027 C15.5652,1.17067 15.575,0.604261 15.2332,0.27223 C14.8914,-0.0695668 14.325,-0.0695668 13.9929,0.27223 L7.74293,6.52223 L1.49293,0.27223 C1.1609,-0.0695668 0.58473,-0.0793324 0.252699,0.27223 C-0.0793324,0.614027 -0.0793324,1.17067 0.252699,1.5027 L6.5027,7.7527 L0.252699,14.0125 C-0.0793324,14.3445 -0.0890981,14.9109 0.252699,15.2429 Z"
            id="Path"
            fill-opacity="0.85"
          ></path>
        </g>
      </g>
    </svg>
  );
}

Close.defaultProps = {
    height: "16px",
    fill: "#000000",
    width: "16px",
    title: "close"
}

export default Close