import React, { useState, useEffect, useRef } from "react";
import { Chip, Button, Typography, makeStyles, CircularProgress, Box} from "../../libraries/material/core";
import {
    Alert
} from "../../libraries/material/lab";
import { Fragment } from "react";
import { green } from '@material-ui/core/colors';
import { FileCopy, CloudUpload, Check } from "@material-ui/icons";
import PropTypes from 'prop-types';
import PDFModal from "./viewPDFModal";
import { generateUUID } from "../../utilities";
import { mimeTypes } from "../enums";

const useStyles = makeStyles((theme) => ({
    dropzone: {
        position: "relative",
        boxShadow: "0 0 0 100vmax rgba(51, 50, 50, 0.767)",
        borderRadius: theme.spacing(),
        padding: "2rem !important",
        margin: "-2rem !important",
        zIndex: theme.zIndex.modal
    },
    osuFileUpload: {
        paddingTop: "1.1rem",
        paddingBottom: "1.1rem"
    },
    progressCircle: {
        width: "1.5em",
        height: "1.5em"
    },
    fileSuccess: {
        color: theme.palette.getContrastText(green[300]),
        backgroundColor: green[300],
        '&:hover': {
            color: theme.palette.getContrastText(green[400]),
            backgroundColor: green[400],
        }
    },
    deletable: {
        ["&:focus"]: {
            outline: "-webkit-focus-ring-color auto 5px"
        }
    },
    errorAlert: ({ error }) => {
        return {
            display: error ? "flex" : "none",
            ["&:focus"]: {
                outline: `${theme.palette.error.main} auto 5px`
            }
        }
    },
}));

const createUserFriendlyAcceptString = (accept = "") => {
    let friendlyFileTypes = []
    let parsedFileTypes = accept.replaceAll(" ", "")
    parsedFileTypes = parsedFileTypes.split(",")
    
    parsedFileTypes.forEach(type => {
        let typeToAdd = mimeTypes[type] ?? type
        const matchFound = friendlyFileTypes.filter(currentType => currentType.split(", ").includes(typeToAdd))
        if(matchFound.length === 0) {
            friendlyFileTypes.push(typeToAdd)
        }
    })
    
    return friendlyFileTypes.join(", ")
}

function FileUploader(props = {}) {
    //incoming props:
    // setProgress, progress, errorText, onDrop, onClick, onFileUpload, multiple, preventOutOfBounds, onDelete, accept, disabled, defaultValue, progressVariant
    let { setProgress, progress, errorText, onDrop, onClick, onFileUpload, multiple, preventOutOfBounds, onDelete, accept, disabled, defaultValue, progressVariant, helperTextProps, buttonText, inputProps, maxFileSize, ...rest } = props;
    const HelperText = (typeof props.helperText === "function") 
        ? props.helperText 
        : typeof props.helperText === "string" 
            ? (htProps) => <Typography component="p" {...htProps}>{props.helperText}</Typography> 
            : null;
    const inputRef = useRef()
    const mainButtonRef = useRef()
    const chipRef = useRef()
    const errorRef = useRef()
    const buttonId = (props?.id) ? props.id + "osu-react-ui-file-uploader-button" : generateUUID("ori-file-upload")
    const circularProgressId = (props?.id) ? props.id + "ori-file-loading" : generateUUID("ori-file-loading")
    const chipInstructionsId =  (props?.id) ? props.id + "chip-instruct" : generateUUID("chip-instruct")
    const errorId = (props?.id) ? props.id + "ori-error" : generateUUID("ori-error")
    const successId = (props?.id) ? props.id + "ori-success" : generateUUID("ori-success")
    const displayableAccept = createUserFriendlyAcceptString(accept)
    if (!progress) {
        progress = 0;
    }

    if (!progressVariant) {
        progressVariant = "indeterminate"
    }

    if (!setProgress) {
        [progress, setProgress] = useState(progress);
    }

    const [isDragging, setIsDragging] = useState(false);
    const [error, setError] = useState(null);
    const [fileData, manageFileData] = useState([]);
    const [chips, setChips] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const setFileData = (newFileData) => {
        let okFiles = []
        let errors = []
        let fileSizeErr = `Maximum file size supported is ${maxFileSize}MB`
        const convertedMaxSize = maxFileSize * 1024 * 1024
        if(Array.isArray(newFileData)) {
            newFileData.forEach(file => {
                if(file.size > convertedMaxSize) {
                    errors.push(fileSizeErr)
                } else {
                    okFiles.push(file)
                }
            })
        } else {
            if(newFileData.size > convertedMaxSize) {
                errors.push(fileSizeErr)
            } else {
                okFiles.push(newFileData)
            }
        }

        if(!multiple && newFileData.length > 1) {
            errors.push(`Please upload only one file`)
        }

        manageFileData(newFileData)
    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }

    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsDragging(false);

        if (!multiple && e.dataTransfer.items.length > 1) {
            setError("Please drop only 1 file");
        }
        else {
            let files = [];
            for (let file of e.dataTransfer.items) {
                if (accept) {
                    if (!file.type || !accept.includes(file.type)) {
                        setError("Please only drop files of type: " + displayableAccept);
                        return;
                    }
                }
                let asFile = file.getAsFile()
                files.push(asFile);
            }

            setError(null);
            setFileData([...files]);
            if (progress !== 1)
                setProgress(1);

            if (onDrop)
                onDrop([...files]);
            if (onFileUpload)
                onFileUpload([...files]);
        }

        if(mainButtonRef?.current && (document.activeElement.id === props.id)) {
            mainButtonRef?.current?.focus()
        }
    };

    const handleFileSelect = (e) => {
        setFileData([...e.target.files]);
        if (progress !== 1)
            setProgress(1);
        setError(null);

        if (onFileUpload)
            onFileUpload([...e.target.files]);
        if (onClick)
            onClick([...e.target.files]);
    }

    const handleWrongDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    }

    useEffect(() => {
        setError(errorText);
    }, [errorText])

    useEffect(() => {
        document.addEventListener("dragover", handleDrag);
        document.addEventListener("dragleave", handleDragOut);
        if (preventOutOfBounds) {
            document.addEventListener("drop", handleWrongDrop);
        }
        return function cleanup() {
            document.removeEventListener("dragover", handleDrag);
            document.removeEventListener("dragleave", handleDragOut);
            if (preventOutOfBounds) {
                document.removeEventListener("drop", handleWrongDrop);
            }
        }
    });

    useEffect(() => {
        if (props?.defaultValue?.[0]?.id) {
            setFileData(defaultValue)
            setProgress(100);
        }
    }, [props?.defaultValue?.[0]?.id])


    useEffect(() => {
        generateChips();
    }, [fileData]);

    useEffect(() => {
        if(errorRef?.current && error && (document.activeElement !== errorRef.current)) {
            errorRef.current.focus()
        }
    }, [error])

    const closeModal = () => {
        setModalOpen(false);
        setModalData(null);
    }

    let handleDelete = (e) => {
        if (disabled) {
            return;
        }

        let newFileData = [];
        for (let file of fileData) {
            if (file.name !== e) {
                newFileData.push(file);
            }
        }

        if (newFileData.length === 0) {
            setProgress(0);
        }
        setFileData([...newFileData]);
        if (onDelete)
            onDelete({ newFiles: newFileData, deleted: e });
    }

    const generateChips = () => {
        let idx = 0
        for (let file of fileData) {
            setChips(existingChips => {
                let newChips = existingChips ? existingChips : [];
                if (!multiple) {
                    newChips = [];
                }
                const nameTrimmed = file.name.substring(0, 20);
                let opts = {};
                if (accept === "application/pdf") {
                    opts['onClick'] = () => { props.onFileClick(file) };
                }
                if (idx === 0) {
                    opts.ref = chipRef
                }
                newChips.push( <Chip 
                        color="default" 
                        size="small" 
                        {...opts} 
                        key={file.name + idx} 
                        className={`padding-right-1 padding-left-1 margin-right-1 ${classes.deletable}`} 
                        onDelete={(e) => {
                            handleDelete(file.name)
                            mainButtonRef?.current?.focus()
                        }} 
                        icon={<FileCopy />} 
                        label={nameTrimmed}
                        aria-describedby={chipInstructionsId}
                    />
               );
                setChips(newChips);
            });

            idx ++
        }
    }
    
    const showCircularProgress = progress > 0 && progress < 100

    const classes = useStyles({ error });
    let cls = isDragging ? classes.dropzone : classes.osuFileUpload
    if(rest?.className) {
        cls += ` ${rest.className}`
    }

    let describedby = undefined
    if(error) {
        describedby = errorId
    } if(showCircularProgress) {
        describedby = circularProgressId
    } else if (progress !== 100) {
        describedby = props.id + "ht-drag"
        if(maxFileSize) describedby += ` ${props.id + "ht-file-size"}`
        if(displayableAccept) describedby += ` ${props.id + "ht-file-type"}`
        if(HelperText) describedby += " " + (helperTextProps?.id || (props.id + "ht-custom"))
    }

    const instructionsText = props?.instructionsText || `Click or drag to ${buttonText.toLowerCase()}${!multiple ? "(one file only)" : ""}`
    const sizeText = props?.sizeText || `File${multiple ? "s" : ""} must be less than ${maxFileSize}MB`
    const acceptText = props?.acceptText || `Allowed file types: ${displayableAccept}`
    
    return (
        <Fragment>
            <div id={props.id} className={cls}
                onDrop={e => handleDrop(e)}
                onDragOver={e => handleDrag(e)}
                onDragEnter={e => handleDrag(e)}
                onDragLeave={e => handleDragOut(e)}
                >
                {disabled !== true && <Fragment>
                    <label htmlFor={props.id + "-btn-upload"}>
                        <input ref={inputRef} type="file" multiple={multiple} id={props.id + "-btn-upload"} name={props.id + "-btn-upload"} style={{ display: 'none' }} onChange={handleFileSelect} accept={accept} {...inputProps} />
                        <Button id={buttonId} ref={mainButtonRef} onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if(inputRef?.current) {
                                inputRef.current.click(e)
                            }
                        }}  
                        variant="outlined" 
                        color={progress === 100 ? "" : "primary"} 
                        className={progress === 100 ? classes.fileSuccess : ""} 
                        size="small" 
                        aria-describedby={describedby}
                        aria-invalid={error}
                        aria-errormessage={errorId}
                        aria-busy={showCircularProgress}
                        startIcon={showCircularProgress 
                            ? <CircularProgress id={circularProgressId} size={20} variant={progressVariant} value={progress} /> 
                            : progress === 100 ? <Check aria-labelledby={successId} className={classes.fileSuccess} /> : <CloudUpload />}>
                            {buttonText}
                        </Button>
                    </label>
                    <Typography id={successId} role="status" aria-live={progress === 100 ? "assertive" : "off"} variant="srOnly">
                        {(progress === 100) ? `${chips?.length} uploaded successfully` : ""}
                    </Typography>
                    <Box display="flex" flexDirection="column" marginTop="0.5rem">
                        <Typography id={props.id + "ht-drag"} variant="caption" color="textSecondary">{instructionsText}{" "}</Typography> 
                        {maxFileSize && <Typography id={props.id + "ht-file-size"} variant="caption" color="textSecondary">{sizeText}{" "}</Typography> }
                        {displayableAccept && <Typography id={props.id + "ht-file-type"}  variant="caption" color="textSecondary">{acceptText}{" "}</Typography> }
                        {HelperText && <HelperText {...helperTextProps} id={helperTextProps?.id || (props.id + "ht-custom")} />}
                    </Box>
                </Fragment>
                }
                {progress === 100 && <div className="padding-top-2">
                    {chips}
                    <Typography id={chipInstructionsId} variant="srOnly">delete or backspace to remove</Typography>
                </div>} 
                <Alert aria-live={error ? "assertive" : "off"} className={classes.errorAlert} id={errorId} severity="error">{error}</Alert>
                <PDFModal closeModal={closeModal} file={modalData} open={modalOpen} />
            </div>
        </Fragment>
    );
}

FileUploader.defaultProps = {
    preventOutOfBounds: true,
    multiple: false,
    helperText: '',
    helperTextProps: {},
    buttonText: 'UPLOAD',
    maxFileSize: 20,
    inputProps: {},
    accept: "application/msword, .doc, .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/gif, .gif, image/png, .png, image/jpeg, .jpeg, image/bmp, .bmp, image/tiff, .tiff, text/plain, application/pdf, .txt"
};

FileUploader.propTypes = {
    id: PropTypes.string.isRequired,
    onDrop: PropTypes.func,
    onClick: PropTypes.func,
    onFileUpload: PropTypes.func,
    onFileClick: PropTypes.func,
    setProgress: PropTypes.func,
    progress: PropTypes.number,
    multiple: PropTypes.bool,
    preventOutOfBounds: PropTypes.bool,
    onDelete: PropTypes.func,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType(PropTypes.object, PropTypes.array),
    errorText: PropTypes.string,
    progressVariant: PropTypes.string,
    helperTextProps: PropTypes.object,
    helperText: PropTypes.oneOfType(PropTypes.string, PropTypes.func), // string or class/functional component
    buttonText: PropTypes.string.isRequired,
    inputProps: PropTypes.object,
    maxFileSize: PropTypes.number,
    instructionsText: PropTypes.string,
    sizeText: PropTypes.string,
    acceptText: PropTypes.string
}

export default FileUploader;