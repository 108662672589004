import React from "react";
import PropTypes from "prop-types";
import {
  Drawer as MuiDrawer,
  List,
  Toolbar,
  makeStyles
} from "../../libraries/material/core";
import {
  Skeleton
} from "../../libraries/material/lab";
import StylizedLink from "./StylizedLink";
import { tocPathShape } from "../../utilities/prop-types";

const useStyles = makeStyles(() => {
  return {
    drawer: ({ width }) => ({
      width,
      flexShrink: 0,
    }),
    drawerPaper: ({ width }) => ({
      width,
      backgroundColor: "transparent",
    }),
  };
});

const Sidebar = (props = {}) => {
  const {
    id: sidebarId,
    className,
    spacingTop,
    variant,
    anchor,
    classes: propCls,
    PaperProps,
    StylizedLinkProps,
    width,
    paths,
    hideOnEmpty,
    ...rest
  } = props;
  const classes = useStyles({ width });

  if(paths.length < 1 && hideOnEmpty) {
    return null
  }

  let drawerClasses = Object.assign({}, propCls);
  drawerClasses.paper = `${classes.drawer} ${propCls.paper || ""}`.trim();
  drawerClasses.paperAnchorDockedRight = `${classes.drawer} ${
    propCls.paper || ""
  }`.trim();

  let paperProps = Object.assign(
    {
      "aria-label": "on this page",
      component: "nav",
    },
    PaperProps
  );
  
  return (
    <MuiDrawer
      {...rest}
      id={sidebarId}
      variant={variant}
      anchor={anchor}
      className={`${classes.drawer} ${className}`.trim()}
      classes={drawerClasses}
      PaperProps={paperProps}
    >
      {spacingTop && <Toolbar />}
      <List>
        {paths.map(({ id, label, loading, percentVisible }, index) => {
          const href = `#${id}`;
          if (loading) {
            return (
              <li key={`sidebar-link-loading-${index}${href}`}>
                <Skeleton className="margin-x-1" />
              </li>
            );
          }
          return (
            <li key={`sidebar-link-${index}${href}`}>
              <StylizedLink
                pathId={id}
                href={href}
                percentVisible={percentVisible}
                {...StylizedLinkProps}
              >
                {label}
              </StylizedLink>
            </li>
          );
        })}
      </List>
    </MuiDrawer>
  );
};

Sidebar.defaultProps = {
  hideOnEmpty: true,
  id: "table-of-contents",
  className: "",
  variant: "permanent",
  anchor: "right",
  classes: {},
  paths: [],
  StylizedLinkProps: {},
  width: "15rem",
  spacingTop: true,
};

Sidebar.propTypes = {
  id: PropTypes.string,
  spacingTop: PropTypes.bool,
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      loading: PropTypes.bool,
      visible: PropTypes.bool,
      percentVisible: PropTypes.shape(tocPathShape),
    })
  ),
  variant: PropTypes.oneOf(["permanent", "persistent", "temporary"]),
  className: PropTypes.string,
  anchor: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  classes: PropTypes.object,
  PaperProps: PropTypes.object,
  StylizedLinkProps: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideOnEmpty: PropTypes.bool
};

export default Sidebar