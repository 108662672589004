const typography = {
  h1: {
    fontSize: "3rem",
  },
  h2: {
    fontSize: "2.5rem",
  },
  h3: {
    fontSize: "2rem",
  },
  h4: {
    fontSize: "1.75rem",
  },
  h5: {
    fontSize: "1.5rem",
  },
  h6: {
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  subtitle2: {
    fontWeight: 600,
  },
  button: {
    fontWeight: 600,
  },
  fontWeightMedium: 600,
  fontSize: 16,
  fontFamily: [
    "proximanova",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
};

export default typography