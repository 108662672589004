import React from "react";
import Title from "./Title";
import { Mui } from "../../libraries/material";

const useStyles = Mui.makeStyles({
  field: {
    minWidth: "10rem",
    maxWidth: "50%",
    display: "inline-block",
  },
});

const Response = (props = {}) => {
  const { label, value, bold, ...rest } = props;
  const classes = useStyles();

  if (Array.isArray(value)) {
    return (
      <Mui.Box>
        <Title className={`${bold ? "bold" : ""} ${classes.field}`.trim()} label={label} />
        {value.map((nestedItem, nestedIdk) => (
          <View
            key={`${encodeURIComponent(label)}-${encodeURIComponent(
              value
            )}-nested-${nestedIdk}`}
            l={nestedItem?.label}
            bold={false}
            v={nestedItem?.value}
            className="padding-left-2 border-left"
          />
        ))}
      </Mui.Box>
    );
  }
  return <View bold={bold} classes={classes} label={label} value={value} />;
};

const View = ({ label, value, bold, classes }) => {
  let cls = `${bold ? "bold " : ""}${classes.field}`;
  
  return (
    <Mui.Typography {...rest}>
      <Title className={cls} label={label} />
      <Mui.Typography component="span" variant="body1">
        {" "}
        {value ?? "-"}
      </Mui.Typography>
    </Mui.Typography>
  );
};

Response.defaultProps = {
  bold: true,
};

export default Response;
