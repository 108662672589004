import React, { Fragment, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet"

function sendPageView(location = {}) {
  let path = location?.pathname;
  if (location.search) path += location.search;

  ReactGA.set({ page: path });
  ReactGA.send({ hitType: 'pageview', page: path });
}
const withGoogleAnalyticsListener = (Component) => {
  return function _withGoogleAnalyticsListener(props) {
    const {
      gtm = process.env.REACT_APP_GOOGLE_ANALYTICS_GTM || 'GTM-P8MKDW6',
      gtag = process.env.REACT_APP_GOOGLE_ANALYTICS_GTAG || 'G-QM24TSVC80',
      cookieDomain,
      cookieFlags,
      ...rest
    } = props;
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      if(!ReactGA.isInitialized) {
        ReactGA.initialize(gtag, { legacyDimensionMetric: false });
      }
    }, [gtag, ReactGA.isInitialized]);

    useEffect(() => {
      const updateTracking = () => sendPageView(location);
      updateTracking();

      return history.listen(updateTracking);
    }, [history, location]);

    return (
      <Fragment>
        <Helmet>
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtm}');`}</script>
        </Helmet>
        <noscript>
          {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=${gtm}"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>`}
        </noscript>
        <Component {...rest} />
      </Fragment>
    );
  };
};

export default withGoogleAnalyticsListener;
