import {
  CheckCircle,
  RadioButtonUnchecked,
  Warning,
  WatchLater,
} from "../libraries/material/icons";
import { Mui } from "../libraries/material";
import React from "react";
import { formatValue } from "../utilities/functions";
import PropTypes from "prop-types";

const COMPLETE = COMPLETE;
const IN_PROGRESS = IN_PROGRESS;
const DELAYED = DELAYED;

function History(params) {
  const { columns, history, ...rest } = params;

  return (
    <Mui.Stepper orientation="vertical" {...rest}>
      {history.map((record = {}, index) => {
        const { step: label, status, events: rows, ...recordRest } = record;
        let StepIconComponent = record?.StepIconComponent;
        if (!StepIconComponent) {
          StepIconComponent = (props) => <Icon {...props} status={status} />;
        }
        const key = `${encodeURIComponent(label)}-step-${index}`;

        return (
          <Mui.Step active key={key} {...recordRest}>
            <Mui.StepLabel StepIconComponent={StepIconComponent}>
              {label}
            </Mui.StepLabel>
            <Mui.StepContent>
              <Mui.Table aria-label="history">
                <Mui.TableHead>
                  <Mui.TableRow>
                    {columns.map((col, columnIndex) => {
                      return (
                        <Mui.TableCell
                          key={
                            columnIndex +
                            `column-${col.dataField}-${columnIndex}`
                          }
                          width={col.width}
                          align="left"
                        >
                          {col.label}
                        </Mui.TableCell>
                      );
                    })}
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {rows.map((row) => (
                    <Mui.TableRow>
                      {columns.map((col, rowIndex) => {
                        let props =
                          index === 0
                            ? {
                                component: "th",
                                scope: "row",
                              }
                            : {};
                        return (
                          <Mui.TableCell
                            width={col.width}
                            align="left"
                            key={index + `row-${col.dataField}-${rowIndex}`}
                            {...props}
                          >
                            {formatValue(row[col.dataField])}
                          </Mui.TableCell>
                        );
                      })}
                    </Mui.TableRow>
                  ))}
                </Mui.TableBody>
              </Mui.Table>
            </Mui.StepContent>
          </Mui.Step>
        );
      })}
    </Mui.Stepper>
  );
}

History.defaultProps = {
  history: [],
};

const useStyles = Mui.makeStyles((theme) => {
  return {
    icon: {
      padding: ".1em",
      fontSize: "2rem",
    },
    check: {
      color: theme.palette.success.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    progress: {
      color: theme.palette.secondary.main,
    },
    default: {
      color: theme.palette.secondary.light,
    },
  };
});

function Icon({ status, ...rest }) {
  const styles = useStyles();

  if (status === COMPLETE) {
    return <CheckCircle {...rest} classes={{ root: styles.check }} />;
  } else if (status === IN_PROGRESS) {
    return <WatchLater classes={{ root: styles.progress }} />;
  } else if (status === DELAYED) {
    return <Warning classes={{ root: styles.warning }} />;
  }
  return <RadioButtonUnchecked classes={{ root: styles.default }} />;
}

History.propTypes = {
  step: PropTypes.string,
  columns: PropTypes.shape({
    dataField: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  history: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.string,
      status: PropTypes.oneOf([COMPLETE, IN_PROGRESS, DELAYED]),
      events: PropTypes.array,
    })
  ),
};

export default History;
