import { createMuiTheme } from "../libraries/material/core";
import { standardPalette, darkModePalette } from "./colors";
import typography from "./typography";
import overrides from "./overrides";

export const elevations = (darkMode) =>
  darkMode
    ? {
        standard: 2,
        high: 4,
        navigation: 6,
        tabs: 6,
        drawer: 10,
      }
    : {
        standard: 1,
        high: 2,
        tabs: 2,
        navigation: 4,
        drawer: 10,
      };

const constructTheme = (darkMode) =>
  createMuiTheme({
    typography,
    overrides: overrides(darkMode, elevations(darkMode)),
    palette: darkMode ? darkModePalette : standardPalette,
  });

export default constructTheme;
