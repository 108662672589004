import React from "react";
import NavLinksListItem from "./ListItem";

function NavLinksList(props = {}) {
  const links = [
    {
      href: "http://buckeyelink.osu.edu/",
      children: "BuckeyeLink",
    },
    {
      href: "https://www.osu.edu/findpeople.php",
      children: "Find People",
    },
    {
      href: "https://email.osu.edu/",
      children: "Webmail",
    },
    {
      href: "https://www.osu.edu/search/",
      children: "Search Ohio State",
    },
  ];

  return (
    <ul id="osu-navlinks" className="bux-osu-nav__links">
      {links.map((link, index) => (
        <NavLinksListItem key={`bux-osu-nav-link-${index}`} {...link} />
      ))}
    </ul>
  );
}

export default NavLinksList;
