/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableBody,
  TableCell as _TableCell,
  TableRow,
  Divider,
  List,
  ListItem,
  withStyles
} from "../libraries/material/core";
import { makeStyles } from "../libraries/material/styles";
import ReactMarkdown from "react-markdown";
import _Heading from "./Renderers/_Heading";
import _Link from "./Renderers/_Link";

const TableCell = withStyles(() => ({
  root: {
      minWidth: '5rem'
  },
}))(_TableCell);

// eslint-disable-next-line no-undef
const gfm = require('remark-gfm')

/**
* Built upon react-markdown. Currently supports heading, tables, links, and list, returning Material-UI components. To further customize a renderer, pass over `renderers`
* to the component. GitHub Flavored Markdown is an included remarkPlugin.
* 
* If you are importing from a local file instead of a markdown text string, you may need to set fetch to true. 
*
* Further reading:
*
* - https://www.npmjs.com/package/react-markdown
*
* - https://www.npmjs.com/package/remark-gfm
*/

export default function Markdown(props = {}) {
  const {
    markdown,
    renderers: propRenderers,
    remarkPlugins,
    fetchText,
    ...rest
  } = props;
  const classes = useStyles();
  const [fetchedMd, setFetchedMd] = useState("");

  useEffect(() => {
    if(fetchText) {
      fetch(markdown)
        .then((res) => {
          const text = res.text();
          return text;
        })
        .then((text) => setFetchedMd(text));
    }
  }, [fetchText, markdown]);

  const mdRenderers = (classes = {}) =>
    Object.assign(renderers(classes), propRenderers);

  return (
    <ReactMarkdown
      {...rest}
      renderers={mdRenderers(classes)}
      remarkPlugins={[gfm, ...remarkPlugins]}
    >
      {fetchText ? fetchedMd : markdown}
    </ReactMarkdown>
  );
}

Markdown.defaultProps = {
  remarkPlugins: [],
  fetchText: false
};

Markdown.propTypes = {
  markdown: PropTypes.string,
  remarkPlugins: PropTypes.array,
  fetchText: PropTypes.bool,
  renderers: PropTypes.shape({
    heading: PropTypes.func,
    table: PropTypes.func,
    tableHead: PropTypes.func,
    tableBody: PropTypes.func,
    tableRow: PropTypes.func,
    tableCell: PropTypes.func,
    thematicBreak: PropTypes.func,
    link: PropTypes.func,
    list: PropTypes.func,
    listItem: PropTypes.func,
  }),
};

const useStyles = makeStyles({
  table: {
    wordBreak: "break-word",
  },
});

const renderers = (classes) => ({
  heading: _Heading,
  table: ({ children }) => (
    <Table className={classes.table}>
      {children}
    </Table>
  ),
  tableHead: TableHead,
  tableBody: TableBody,
  tableRow: TableRow,
  tableCell: ({ children, ...rest }) => {
    return <TableCell {...rest} align={rest?.align || 'inherit'}>
      {children}
    </TableCell>
  },
  thematicBreak: () => <Divider className="my-2" />,
  link: _Link,
  list: ({ children }) => <List dense>{children}</List>,
  listItem: ({ children }) => <ListItem disableGutters>{children}</ListItem>,
});